import {
    RootState,
    connect,
    injectReducer,
    injectSaga,
} from '@vfde-sails/core';
import { createStructuredSelector } from 'reselect';
import { NAMESPACE } from 'Constant';
import {
    ActionDispatchers,
    actionDispatchers,
} from './actions';
import flyoutReducer from './reducer';
import {
    selectInjected,
    selectIsOpenedViaNavigation,
    selectIsTobiInitialized,
} from './selector';
import flyoutSaga from './saga';
import {
    BeaconSetActivePage,
    FlyoutContent,
    IFlyoutState,
    StateProps,
} from 'Container/Flyout/interface';
import { mountFlyoutContent } from './helper/mountFlyoutContent';
import { setupInitialUIElements } from './helper/setupInitialUIElements';
import { getContactInfoFromWindow } from './helper/windowObjectHelpers';
import { CONTACT_INFO } from '../../fixtures/mocks/contactInfo';
import {
    GLOBAL_FLYOUT_EVENT,
    GLOBAL_FLYOUT_EVENT_SET_CONTACT,
    GLOBAL_FLYOUT_EVENT_TOGGLE_FLYOUT,
} from './constant';
import beacon, { BeaconAction } from '@vfde-sails/beacon';

const flyout = (state: StateProps, actions: ActionDispatchers) => {
    injectSaga(NAMESPACE, flyoutSaga);
    injectReducer(NAMESPACE, flyoutReducer);

    const overlay = setupInitialUIElements(actions);



    const contactInfo = getContactInfoFromWindow();

    if (contactInfo) {
        actions.setContactInfo(contactInfo);
    }
    else {
        window.addEventListener(GLOBAL_FLYOUT_EVENT_SET_CONTACT, (e: CustomEvent<FlyoutContent>) => {
            actions.setContactInfo(e.detail);
        });
    }

    // Listen to global navigation contact section for opening
    beacon.subscribe(GLOBAL_FLYOUT_EVENT, (msg: BeaconAction<BeaconSetActivePage>) => {
        switch (msg.type) {
            case GLOBAL_FLYOUT_EVENT_TOGGLE_FLYOUT:
                actions.fetchFlyoutContent(overlay, true);

                break;
        }
    });

    return {
        getDerivedStateFromProps (newState: StateProps, oldState: StateProps) {
            if (newState.isInjected && !oldState.isInjected) {
                mountFlyoutContent(actions, newState.isOpenedViaNavigation);
            }
        },
    };
};

/** State Mapping */
const mapStateToProps = createStructuredSelector<RootState<IFlyoutState>, StateProps>({
    isInjected: selectInjected(),
    isTobiInitialized: selectIsTobiInitialized(),
    isOpenedViaNavigation: selectIsOpenedViaNavigation(),
});

const mountFlyoutContainer = connect(mapStateToProps, actionDispatchers)(flyout);

export default mountFlyoutContainer;
