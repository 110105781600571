/* eslint-disable max-len */
import {
    AccordionVariant,
    IAccordionListProperties,
    createAccordion,
} from '@vfde-brix/ws10/accordion';
import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/ws10/core';
import {
    CALL_ICON,
    CONTAINER_HOTLINE_BUTTON,
    OPENING_HOURS_ACCORDION_ITEM,
} from 'Container/Flyout/constant';
import { getHotlineHeaderFromWindow } from 'Container/Flyout/helper/windowObjectHelpers';

/**
 * mount accordion
 */
export const mountAccordion = (wrapperId: string, props?: IAccordionListProperties): void => {
    const wrapperElement = document.getElementById(wrapperId);

    wrapperElement && createAccordion(wrapperElement, props || NO_PATTERN_BUSINESS_LOGIC);
};

/**
 * mount editorial accordion
 */
export const mountEditorialAccordion = (wrapperElement: HTMLElement, props?: IAccordionListProperties): void => {
    wrapperElement && createAccordion(wrapperElement, props || NO_PATTERN_BUSINESS_LOGIC);
};

/**
 * Take optional contact info and return opening hours accordion item
 */
export const generateOpeningHoursItem = (hotlineNumber: string, openingHours: string): IAccordionListProperties => {
    const stdHeadline = getHotlineHeaderFromWindow();

    return {
        items: [{
            stdId: OPENING_HOURS_ACCORDION_ITEM,
            optOpen: true,
            stdHeadline,
            stdIconLeft: CALL_ICON,
            containerAnyComponent:
                    `<div>
                        <h3 class="ws10-headline ws10-headline--h4">${hotlineNumber}</h3>
                        <p>${openingHours}</p>
                        <br>
                        <div id="${CONTAINER_HOTLINE_BUTTON}"></div>
                        <br>
                    </div>`,
        }],
        optOpenIndividually: false,
        optDeepLink: false,
        optJumpToClickedItem: false,
        optVariant: AccordionVariant.Card,
        business: {},
    };
};
