import {
    createButtonLink,
    IButtonLinkBusinessLogic,
    IButtonLinkProperties,
} from '@vfde-brix/ws10/button-link';

/**
 * Mount buttonLink
 */
export const mountButtonLink = (
    wrapperId: string,
    props?: IButtonLinkProperties | IButtonLinkBusinessLogic,
): void => {
    const wrapperElement = document.getElementById(wrapperId);

    wrapperElement && createButtonLink(wrapperElement, props);
};
