import { getTransitionDurationFromElement } from '@vfde-brix/ws10/core';
import {
    Overlay,
    createOverlay,
    IOverlayProperties,
    OVERLAY_ALIGNMENT_LEFT,
    OVERLAY_CLASSNAME_BACKDROP,
    OVERLAY_CLASSNAME_CONTAINER,
    OVERLAY_ANIMATION_SLIDE,
} from '@vfde-brix/ws10/overlay';
import beacon from '@vfde-sails/beacon';
import {
    CONTAINER_FLYOUT_OVERLAY,
    FLYOUT_CLASSNAME,
    FOCUSABLE_ELEMENTS,
    GLOBAL_FLYOUT_EVENT,
    GLOBAL_FLYOUT_EVENT_TOGGLE_FLYOUT_SUCCESS,
    KEYDOWN_EVENT,
    TAB_EVENT,
} from 'Container/Flyout/constant';

let currentFocusListener = null;

/**
 * mount overlay
 */
export const mountOverlay = (): Overlay | null => {
    let overlayContainer = document.getElementById(CONTAINER_FLYOUT_OVERLAY);
    const props: IOverlayProperties = {
        optContentAlign: OVERLAY_ALIGNMENT_LEFT,
        optAnimation: OVERLAY_ANIMATION_SLIDE,
        optPaddingLayout: true,
        optAutoFocus: false,
        business: {
            onClose: () => {
                beacon.publish(GLOBAL_FLYOUT_EVENT, {
                    type: GLOBAL_FLYOUT_EVENT_TOGGLE_FLYOUT_SUCCESS,
                    payload: {
                        activePage: null,
                    },
                });
            },
        },
    };

    if (!overlayContainer) {
        overlayContainer = document.createElement('div');
        overlayContainer.id = CONTAINER_FLYOUT_OVERLAY;
        overlayContainer.className = FLYOUT_CLASSNAME;
        document.body.append(overlayContainer);
    }

    return overlayContainer && createOverlay(overlayContainer, props);
};

/**
 * TODO: once it's fixed in brix we can remove this
 * Update the focusable elements reference
 */
export const updateFocusableElementsWithinOverlay = () => {
    const overlayContainer = document.getElementsByClassName(OVERLAY_CLASSNAME_CONTAINER)[0] as HTMLElement;

    if (!overlayContainer) {
        return;
    }

    const focusableElements = (Array.from(overlayContainer.querySelectorAll(FOCUSABLE_ELEMENTS)) as HTMLElement[])
        .filter(el => el.offsetParent !== null);

    focusableElements?.[0]?.focus();

    setupFocusManagementForElements(focusableElements, overlayContainer);
};

/**
 * TODO: once it's fixed in brix we can remove this
 *  Manage, clear and setup focus event
 */
export const setupFocusManagementForElements = (focusableElements: HTMLElement[], overlayContainer: HTMLElement) => {
    // Clean up the previous listener if it exists
    if (currentFocusListener) {
        overlayContainer.removeEventListener(KEYDOWN_EVENT, currentFocusListener);
    }

    // Define a new focus listener
    currentFocusListener = function (e) {
        if (e.key !== TAB_EVENT) {
            return;
        }

        e.preventDefault();
        const currentIndex = focusableElements.indexOf(document.activeElement as HTMLElement);
        let nextIndex = currentIndex + (e.shiftKey ? -1 : 1);

        if (nextIndex >= focusableElements.length) {
            nextIndex = 0;
        }

        if (nextIndex < 0) {
            nextIndex = focusableElements.length - 1;
        }

        focusableElements[nextIndex].focus();

        // This way it won't conflict with the overlay's original focus which right now does not work in some scenarios
        e.stopImmediatePropagation();
    };

    overlayContainer.addEventListener(KEYDOWN_EVENT, currentFocusListener);
};

/**
 * Reference the overlay's backdrop transition to have a way of knowing the overlay has opened
 */
export const openOverlayWithPromise = overlay => new Promise<void>(resolve => {
    overlay.open();

    const overlayBackdropElement = document.body.getElementsByClassName(OVERLAY_CLASSNAME_BACKDROP)[0] as HTMLElement;
    const overlayElement = overlay.overlayElement;

    // Calculate max transition duration safely
    const maxTransitionDuration = Math.max(
        getTransitionDurationFromElement(overlayBackdropElement),
        getTransitionDurationFromElement(overlayElement),
    );

    setTimeout(() => {
        resolve();
    }, maxTransitionDuration);
});
