import {
    FETCH_FLYOUT_CONTENT,
    INITIALIZE_TOBI,
    INITIALIZE_TOBI_SUCCESS,
    INJECT_CONTENT_SUCCESS,
    SAVE_ERROR,
    SET_CONTACT_INFO,
} from './constant';
import { ExtractActions } from '@vfde-sails/core';
import { Overlay } from '@vfde-brix/ws10/overlay';
import { FlyoutContent } from './interface';

/**
 * Fetch Flyout content
 */
const fetchFlyoutContent = (flyoutOverlay: Overlay | null, hotline = false) => ({
    type: FETCH_FLYOUT_CONTENT,
    flyoutOverlay,
    hotline,
});

/**
 * Set contact info
 */
const setContactInfo = (simplicityContactInfo: FlyoutContent) => ({
    type: SET_CONTACT_INFO,
    simplicityContactInfo,
});

/**
 * Inject content successful
 */
const injectContentSuccess = (isOpenedViaNavigation = false) => ({
    type: INJECT_CONTENT_SUCCESS,
    isOpenedViaNavigation,
});

/**
 * Start and configurate TOBI
 */
const initializeTobi = () => ({
    type: INITIALIZE_TOBI,
});

/**
 * TOBI has started successfully
 */
const initializeTobiSuccess = () => ({
    type: INITIALIZE_TOBI_SUCCESS,
});

const saveErrorMessage = (errorMessage: string) => ({
    type: SAVE_ERROR,
    errorMessage,
});

/**
 * Actions creators
 */
export const actionCreators = {
    fetchFlyoutContent,
    injectContentSuccess,
    initializeTobi,
    initializeTobiSuccess,
    setContactInfo,
    saveErrorMessage,
};

/**
 * Action dispatchers (ex mapDispatch)
 */
export const actionDispatchers = {
    fetchFlyoutContent,
    injectContentSuccess,
    initializeTobi,
    initializeTobiSuccess,
    setContactInfo,
    saveErrorMessage,
};

/**
 * Utility type to get the type for the actions
 */
export type ActionCreators = typeof actionCreators;

/**
 * Action dispatchers
 */
export type ActionDispatchers = typeof actionDispatchers;
/**
 * Flyout actions
 */
export type FlyoutAction = ExtractActions<ActionCreators>;
