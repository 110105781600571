import { trackIt } from '@vfde-sails/tracking';
import {
    FLYOUT_TRACKING_ACTION,
    FLYOUT_TRACKING_ACTION_ENGAGE,
    FLYOUT_TRACKING_ACTION_REVEAL,
    FLYOUT_TRACKING_AVAILABLE_TOOLS,
    FLYOUT_TRACKING_AVAILABLE_TOOLS_DEFAULT,
    FLYOUT_TRACKING_EVENT,
    FLYOUT_TRACKING_PHONE_NUMBER,
    FLYOUT_TRACKING_SUBJECT,
    FLYOUT_TRACKING_SUBJECT_CONTACT_SUPPORT,
    FLYOUT_TRACKING_TOOL,
    FLYOUT_TRACKING_TOOL_HOTLINE,
    FLYOUT_TRACKING_TRIGGER,
    FLYOUT_TRACKING_TRIGGER_BUTTON,
    FLYOUT_TRACKING_TRIGGER_ICON,
    FLYOUT_TRACKING_VARIANT,
    FLYOUT_TRACKING_VARIANT_REACTIVE,
} from '../constant';

/**
 * track hotline information
 */
export const trackHotline = (hotlineNumber = '') => {
    trackIt(
        FLYOUT_TRACKING_EVENT,
        {
            [FLYOUT_TRACKING_ACTION]: FLYOUT_TRACKING_ACTION_ENGAGE,
            [FLYOUT_TRACKING_SUBJECT]: FLYOUT_TRACKING_SUBJECT_CONTACT_SUPPORT,
            [FLYOUT_TRACKING_TOOL]: FLYOUT_TRACKING_TOOL_HOTLINE,
            [FLYOUT_TRACKING_TRIGGER]: FLYOUT_TRACKING_TRIGGER_BUTTON,
            [FLYOUT_TRACKING_AVAILABLE_TOOLS]: FLYOUT_TRACKING_AVAILABLE_TOOLS_DEFAULT,
            [FLYOUT_TRACKING_PHONE_NUMBER]: hotlineNumber,
            [FLYOUT_TRACKING_VARIANT]: FLYOUT_TRACKING_VARIANT_REACTIVE,
        },
    );
};

/**
 * track flyout opening
 */
export const trackFlyoutOpening = () => {
    trackIt(
        FLYOUT_TRACKING_EVENT,
        {
            [FLYOUT_TRACKING_ACTION]: FLYOUT_TRACKING_ACTION_REVEAL,
            [FLYOUT_TRACKING_SUBJECT]: FLYOUT_TRACKING_SUBJECT_CONTACT_SUPPORT,
            [FLYOUT_TRACKING_TOOL]: FLYOUT_TRACKING_TOOL_HOTLINE,
            [FLYOUT_TRACKING_TRIGGER]: FLYOUT_TRACKING_TRIGGER_ICON,
            [FLYOUT_TRACKING_AVAILABLE_TOOLS]: FLYOUT_TRACKING_AVAILABLE_TOOLS_DEFAULT,
            [FLYOUT_TRACKING_VARIANT]: FLYOUT_TRACKING_VARIANT_REACTIVE,
        },
    );
};

/**
 * track flyout view change
 */
export const trackFlyoutViewChange = (tool: string) => {
    trackIt(
        FLYOUT_TRACKING_EVENT,
        {
            [FLYOUT_TRACKING_ACTION]: FLYOUT_TRACKING_ACTION_ENGAGE,
            [FLYOUT_TRACKING_SUBJECT]: FLYOUT_TRACKING_SUBJECT_CONTACT_SUPPORT,
            [FLYOUT_TRACKING_TOOL]: tool,
            [FLYOUT_TRACKING_TRIGGER]: FLYOUT_TRACKING_TRIGGER_BUTTON,
            [FLYOUT_TRACKING_AVAILABLE_TOOLS]: FLYOUT_TRACKING_AVAILABLE_TOOLS_DEFAULT,
            [FLYOUT_TRACKING_VARIANT]: FLYOUT_TRACKING_VARIANT_REACTIVE,
        },
    );
};
