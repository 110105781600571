import {
    CONTAINER_HOTLINE_BUTTON,
    NAVIGATION_HOTLINE,
    OPENING_HOURS_ACCORDION_ITEM_ID,
} from '../constant';
import { HEADLINE_BASE_CLASSNAME } from '@vfde-brix/ws10/headline';

/**
 * Check if hotline number is the same in the nav modules and in the accordion
 */
export const compareHotlineNumbers = (): void => {
    // nav modules
    const navHotline = document.getElementsByClassName(NAVIGATION_HOTLINE)[0]?.innerHTML;

    if (!navHotline) {
        return;
    }

    const navHotlineNumber = extractPhoneNumber(navHotline);

    // flyout hotline
    const flyoutHotline = document.querySelector(`#${OPENING_HOURS_ACCORDION_ITEM_ID} .${HEADLINE_BASE_CLASSNAME}`);

    const flyoutNumber = extractPhoneNumber(flyoutHotline.innerHTML);

    if (navHotlineNumber !== flyoutNumber) {
        const hotlineButtonlink = document.querySelector(`#${CONTAINER_HOTLINE_BUTTON} a`) as HTMLAnchorElement;
        flyoutHotline.innerHTML = navHotlineNumber;
        hotlineButtonlink.href = `tel:${navHotlineNumber}`;
    }
};

/**
 * Extract phone number from text
 */
export const extractPhoneNumber = (text: string): string => {
    const phoneNumberRegex = /[\d\s]+/g;
    const phoneNumberMatches = text.match(phoneNumberRegex);

    return phoneNumberMatches ? phoneNumberMatches.join('').trim() : '';
};
