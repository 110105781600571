import { mountButtonLink } from '../../../components/ButtonLink';
import { mountLinkTiles } from '../../../components/LinkTiles';
import {
    CONTAINER_HOTLINE_BUTTON,
    FLYOUT_TRACKING_TOOL_ONLINE,
    HILFE_BUTTON_ID,
    HOTLINE_HEADLINE_ID,
    HOTLINE_OPENING_HOURS_ID,
    OPENING_HOURS_CONTAINER,
    RESET_VIEW_CONTAINER,
    TOBI_WEBCHAT_BUTTON_CONTAINER,
} from '../constant';
import {
    generateOpeningHoursItem,
    mountAccordion,
} from '../../../components/Accordion';
import { ActionDispatchers } from '../actions';
import {
    changeView,
    resetView,
} from './uiHelpers';
import { mountEditorialContent } from './mountEditorialContent';
import {
    getContactInfoFromWindow,
    getHotlineButtonTextFromWindow,
} from './windowObjectHelpers';
import {
    trackFlyoutViewChange,
    trackHotline,
} from './tracking';
import { ButtonLinkColorVariant } from '@vfde-brix/ws10/button-link';

/**
 * mount UI components inside the flyout overlay
 */
export const mountFlyoutContent = (actions: ActionDispatchers, isOpenedViaNavigation: boolean): void => {
    const defaultNum = document.getElementById(HOTLINE_HEADLINE_ID).innerHTML;
    const defaultOpeningHours = document.getElementById(HOTLINE_OPENING_HOURS_ID).innerHTML;
    const contactInfo = getContactInfoFromWindow();
    const hotlineText = getHotlineButtonTextFromWindow();
    const hotlineNum = contactInfo?.mobileCallNumber || defaultNum;
    const openingHours = contactInfo?.mobileCallTimes || defaultOpeningHours;

    mountEditorialContent();
    mountLinkTiles();
    mountButtonLink(RESET_VIEW_CONTAINER, { onClick: resetView });

    const openingHoursProps = generateOpeningHoursItem(hotlineNum, openingHours);
    mountAccordion(OPENING_HOURS_CONTAINER, openingHoursProps);
    mountButtonLink(TOBI_WEBCHAT_BUTTON_CONTAINER, { onClick: actions.initializeTobi });
    mountButtonLink(HILFE_BUTTON_ID, { onClick: hilfeButtonHelper });

    const props = {
        optColorVariant: ButtonLinkColorVariant.Monochrome600,
        linkHref: `tel:${hotlineNum}`,
        stdLabel: `<b>${hotlineText}</b>`,
        business: {
            onClick: () => {
                trackHotline(hotlineNum);
                // TODO: Brix block events and the hotline's href is not triggered
                // It should be fixed in brix
                const hotlineButtonlink = document.querySelector(`#${CONTAINER_HOTLINE_BUTTON} a`) as HTMLAnchorElement;
                window.location.href = hotlineButtonlink.href;
            },
        },
    };
    mountButtonLink(CONTAINER_HOTLINE_BUTTON, props);

    if (isOpenedViaNavigation) {
        changeView(null, true);

        return;
    }

    resetView();
};

/**
 * TODO: Remove after button link is fixed
 * Currently in brix we have an e.preventDefault which means as soon as we add an onclick event the original redirection logic is not tggered anymore
 */
export const hilfeButtonHelper = () => {
    trackFlyoutViewChange(FLYOUT_TRACKING_TOOL_ONLINE);
    const anchor = document.querySelector(`#${HILFE_BUTTON_ID} > a`);
    const href = anchor.getAttribute('href');
    const target = anchor.getAttribute('target');

    if (target === '_blank') {
        window.open(href, '_blank');

        return;
    }

    window.location.href = href;
};
