import { mountOverlay } from '../../../components/Overlay';
import { ActionDispatchers } from '../actions';
import { mountButtonIconOnly } from '../../../components/ButtonIconOnly';
import {
    BUTTON_TRIGGER_FLYOUT,
    FLYOUT_CONTENT_CLASSNAME,
} from '../constant';
import { getHiddenNotchFromWindow } from './windowObjectHelpers';
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
import { trackFlyoutOpening } from './tracking';
import { Overlay } from '@vfde-brix/ws10/overlay';

/**
 * Setup initial UI Elements present on load
 */
export const setupInitialUIElements = (actions: ActionDispatchers): Overlay => {
    const isHidden = getHiddenNotchFromWindow();
    const flyoutOverlay = mountOverlay();

    if (isHidden) {
        document.getElementsByClassName(FLYOUT_CONTENT_CLASSNAME)[0].classList.add(CLASSNAME_HIDDEN);
    }
    else {
        mountButtonIconOnly(BUTTON_TRIGGER_FLYOUT, () => {
            actions.fetchFlyoutContent(flyoutOverlay);

            trackFlyoutOpening();
        });
    }

    return flyoutOverlay;
};
