import {
    createButtonIconOnly,
    IButtonIconOnlyBusinessLogic,
} from '@vfde-brix/ws10/button-icon-only';

/**
 * Mount button icon only
 */
export const mountButtonIconOnly = (wrapperId: string, onClick?: (event: MouseEvent) => void): void => {
    const wrapperElement = document.getElementById(wrapperId);
    const business: IButtonIconOnlyBusinessLogic = {
        onClick,
    };

    wrapperElement && createButtonIconOnly(wrapperElement, business);
};
