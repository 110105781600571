/* eslint-disable max-len */
import {
    StrictEffect,
    all,
    call,
    put,
    select,
    take,
    takeLatest,
} from 'redux-saga/effects';
import {
    BEACON_PUBLISH,
    CONTAINER_FLYOUT_OVERLAY,
    CONTAINER_HOTLINE_BUTTON,
    FETCH_FLYOUT_CONTENT,
    FLYOUT_TRACKING_TOOL_HOTLINE,
    GLOBAL_FLYOUT_EVENT,
    GLOBAL_FLYOUT_EVENT_SET_CONTACT_INFO,
    GLOBAL_FLYOUT_EVENT_TOGGLE_FLYOUT_SUCCESS,
    INITIALIZE_TOBI,
    NAVIGATION_HOTLINE,
    OPENING_HOURS_CONTAINER,
    SET_CONTACT_INFO,
    TOBI_BOT_ICON,
    TOBI_BOT_NAME,
    TOBI_BOT_PATH,
    TOBI_BOT_SUBLINE,
    TOBI_BOT_TOPIC,
    TOBI_WEBCHAT_CONTAINER,
} from './constant';
import {
    selectInjected,
    selectIsTobiInitialized,
} from './selector';
import beacon from '@vfde-sails/beacon';
import {
    END,
    EventChannel,
    eventChannel,
} from 'redux-saga';
import {
    ActionCreators,
    actionCreators,
} from 'Container/Flyout/actions';
import { requestHtml } from '@vfde-sails/utils';
import {
    loadTobiCSS,
    loadTobiScript,
} from './helper/loadTOBiResources';
import {
    changeView,
    resetView,
    showTOBiView,
} from './helper/uiHelpers';
import { openOverlayWithPromise } from '../../components/Overlay';
import {
    getContentUrlFromWindow,
    getHotlineButtonTextFromWindow,
} from './helper/windowObjectHelpers';
import {
    generateOpeningHoursItem,
    mountAccordion,
} from '../../components/Accordion';
import { mountButtonLink } from '../../components/ButtonLink';
import { trackHotline } from './helper/tracking';
import { ButtonLinkColorVariant } from '@vfde-brix/ws10/button-link';

const {
    initializeTobiSuccess,
    injectContentSuccess,
    saveErrorMessage,
} = actionCreators;

/**
 * Export generator function
 */
export default function* flyoutSaga () {
    yield takeLatest(FETCH_FLYOUT_CONTENT, fetchFlyoutContentSaga);
    yield takeLatest(INITIALIZE_TOBI, fetchAndInitializeTobi);
    yield takeLatest(SET_CONTACT_INFO, setContactInfoSaga);
}

/**
 * Fetch flyout content or open overlay if content was already injected
 */
export function* fetchFlyoutContentSaga ({ flyoutOverlay, hotline }: ReturnType<ActionCreators['fetchFlyoutContent']>) {
    try {
        const url = getContentUrlFromWindow();
        const isInjected = (yield select(selectInjected())) as boolean;

        if (isInjected && flyoutOverlay) {
            flyoutOverlay.open();
            hotline ? changeView(null, true) : resetView();
        }
        else {
            const response = (yield call(requestHtml, url, {
                encoder: 'utf-8',
            })) as string;

            flyoutOverlay?.injectContent(response);
            yield openOverlayWithPromise(flyoutOverlay);
        }

        if (hotline) {
            yield call(
                [beacon, BEACON_PUBLISH],
                GLOBAL_FLYOUT_EVENT,
                {
                    type: GLOBAL_FLYOUT_EVENT_TOGGLE_FLYOUT_SUCCESS,
                    payload: {
                        activePage: FLYOUT_TRACKING_TOOL_HOTLINE,
                    },
                },
            );
        }

        yield put(injectContentSuccess(hotline));
    }
    catch (e) {
        const error = e as Error;
        yield put(saveErrorMessage(error.message));
    }
}

/**
 * Runs matelso can and publishes to navigation beacon after contact info has been set
 */
export function* setContactInfoSaga (action) {
    try {
        // Publish contact information to global navigation contact section
        yield call(
            [beacon, BEACON_PUBLISH],
            GLOBAL_FLYOUT_EVENT,
            {
                type: GLOBAL_FLYOUT_EVENT_SET_CONTACT_INFO,
                payload: {
                    simplicityContactInfo: {
                        ...action.simplicityContactInfo,
                    },
                },
            },
        );
        document.getElementsByClassName(NAVIGATION_HOTLINE)[0].innerHTML = action.simplicityContactInfo.mobileCallNumber;

        const isInjected = (yield select(selectInjected())) as boolean;

        if (isInjected) {
            const openingHoursProps = generateOpeningHoursItem(action.simplicityContactInfo.mobileCallNumber, action.simplicityContactInfo.mobileCallTimes);
            const hotlineText = getHotlineButtonTextFromWindow();
            mountAccordion(OPENING_HOURS_CONTAINER, openingHoursProps);
            const props = {
                optColorVariant: ButtonLinkColorVariant.Monochrome600,
                linkHref: `tel:${action.simplicityContactInfo.mobileCallNumber}`,
                stdLabel: `<b>${hotlineText}</b>`,
                business: {
                    onClick: () => {
                        trackHotline(action.simplicityContactInfo.mobileCallNumber);
                        // TODO: Brix block events and the hotline's href is not triggered
                        // It should be fixed in brix
                        const hotlineButtonlink = document.querySelector(`#${CONTAINER_HOTLINE_BUTTON} a`) as HTMLAnchorElement;
                        window.location.href = hotlineButtonlink.href;
                    },
                },
            };
            mountButtonLink(CONTAINER_HOTLINE_BUTTON, props);
        }

        yield call(runMatelsoSaga);
    }
    catch (e) {
        const error = e as Error;
        yield put(saveErrorMessage(error.message));
    }
}

/**
 * Run matelso scan after content has been injected
 */
export function* runMatelsoSaga (): Generator<StrictEffect, void> {
    const channel = (yield call(waitForMatelso)) as ReturnType<typeof waitForMatelso>;

    // Matelso scan
    try {
        yield take(channel);
        yield call([window.mtls, 'scan']);
    }
    catch (e: unknown) {
        channel.close();
    }
}

/**
 * Watch for matelso object via interval
 */
export function waitForMatelso (triesLeft = 15): EventChannel<true | Error> {
    return eventChannel(emit => {
        const interval = setInterval(() => {
            if (typeof window.mtls !== 'undefined') {
                emit(true);
                emit(END);
            }
            else if (triesLeft <= 1) {
                emit(new Error('No matelso present.'));
            }

            triesLeft--;
        }, 200);

        return () => {
            clearInterval(interval);
        };
    });
}

/**
 * fetch and inject tobi
 */
export function* fetchAndInitializeTobi (): Generator<StrictEffect, void> {
    const isTobiInitialized = yield select(selectIsTobiInitialized());

    try {
        showTOBiView();

        if (!isTobiInitialized) {
            yield all([
                call(loadTobiCSS),
                call(loadTobiScript),
            ]);
        }

        // Some pages spawn the base container for some reason so we need to scope the reference
        const tobiChatContainer = document.getElementById(CONTAINER_FLYOUT_OVERLAY).getElementsByClassName(TOBI_WEBCHAT_CONTAINER)[0];

        window.webchatApi({
            path: TOBI_BOT_PATH,
            topic: TOBI_BOT_TOPIC,
            botName: TOBI_BOT_NAME,
            headline: TOBI_BOT_NAME,
            subline: TOBI_BOT_SUBLINE,
            imgIconSrc: TOBI_BOT_ICON,
        }, tobiChatContainer as HTMLDivElement);

        yield put(initializeTobiSuccess());
    }
    catch (e) {
        const error = e as Error;
        yield put(saveErrorMessage(error.message));
    }
}
