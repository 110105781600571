/* eslint-disable max-len */
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
import {
    CONTAINER_FLYOUT_OVERLAY,
    COVER_ELEMENT_CONTAINER,
    FLYOUT_TOBI_VIEW_NO_PADDING,
    FLYOUT_TRACKING_TOOL_CHAT,
    FLYOUT_TRACKING_TOOL_HOTLINE,
    FLYOUT_VIEW_CLASSNAME,
    MAIN_VIEW_CONTAINER,
    RESET_VIEW_CONTAINER,
    TOBI_WEBCHAT_CONTAINER,
} from '../constant';
import { updateFocusableElementsWithinOverlay } from '../../../components/Overlay';
import { trackFlyoutViewChange } from './tracking';
import { OVERLAY_CONTENT_CLASSNAME } from '@vfde-brix/ws10/overlay';
import { compareHotlineNumbers } from './compareHotlineNumbers';

/**
 * Reset View
 * Show main View, hide other views and the reset button
 */
export const resetView = (): void => {
    const views = Array.from(document.getElementsByClassName(FLYOUT_VIEW_CLASSNAME));
    views.forEach(div => {
        div.classList.add(CLASSNAME_HIDDEN);
    });
    document.getElementById(RESET_VIEW_CONTAINER)?.classList.add(CLASSNAME_HIDDEN);
    document.getElementById(CONTAINER_FLYOUT_OVERLAY)?.getElementsByClassName(TOBI_WEBCHAT_CONTAINER)[0]?.classList.add(CLASSNAME_HIDDEN);
    document.getElementById(COVER_ELEMENT_CONTAINER)?.classList.add(CLASSNAME_HIDDEN);
    document.getElementById(MAIN_VIEW_CONTAINER)?.classList.remove(CLASSNAME_HIDDEN);
    document.getElementById(CONTAINER_FLYOUT_OVERLAY)?.getElementsByClassName(OVERLAY_CONTENT_CLASSNAME)[0].classList.remove(FLYOUT_TOBI_VIEW_NO_PADDING);

    updateFocusableElementsWithinOverlay();
};

/**
 * Change View
 * Show selected View, hide the main view
 */
export const changeView = (clickedElementText?: string, hotline = false): void => {
    trackFlyoutViewChange(FLYOUT_TRACKING_TOOL_HOTLINE);
    const views = Array.from(document.getElementsByClassName(FLYOUT_VIEW_CLASSNAME));
    document.getElementById(MAIN_VIEW_CONTAINER)?.classList.add(CLASSNAME_HIDDEN);
    document.getElementById(RESET_VIEW_CONTAINER)?.classList.remove(CLASSNAME_HIDDEN);
    document.getElementById(COVER_ELEMENT_CONTAINER)?.classList.add(CLASSNAME_HIDDEN);
    document.getElementById(CONTAINER_FLYOUT_OVERLAY)?.getElementsByClassName(OVERLAY_CONTENT_CLASSNAME)[0].classList.remove(FLYOUT_TOBI_VIEW_NO_PADDING);

    if (hotline) {
        views.forEach((div, index) => {
            if (index === 0) {
                div.classList.remove(CLASSNAME_HIDDEN);
            }
            else {
                div.classList.add(CLASSNAME_HIDDEN);
            }
        });
    }
    else {
        views.forEach((div, index) => {
            const divLabel = div.getElementsByTagName('h1')[0]?.textContent?.trim() || '';

            // hotfix: matelso scan is sometimes bugged because the hotline is not visible during the scan
            if (index === 0) {
                compareHotlineNumbers();
            }

            if (clickedElementText === divLabel) {
                div.classList.remove(CLASSNAME_HIDDEN);
            }
            else {
                div.classList.add(CLASSNAME_HIDDEN);
            }
        });
    }

    updateFocusableElementsWithinOverlay();
};

/**
 * Show TOBi view
 * Hide other views
 */
export const showTOBiView = (): void => {
    trackFlyoutViewChange(FLYOUT_TRACKING_TOOL_CHAT);
    const views = Array.from(document.getElementsByClassName(FLYOUT_VIEW_CLASSNAME));
    views.forEach(div => {
        div.classList.add(CLASSNAME_HIDDEN);
    });
    document.getElementById(CONTAINER_FLYOUT_OVERLAY)?.getElementsByClassName(TOBI_WEBCHAT_CONTAINER)[0].classList.remove(CLASSNAME_HIDDEN);
    document.getElementById(CONTAINER_FLYOUT_OVERLAY)?.getElementsByClassName(OVERLAY_CONTENT_CLASSNAME)[0].classList.add(FLYOUT_TOBI_VIEW_NO_PADDING);

    updateFocusableElementsWithinOverlay();
};
