import { ACCORDION_BASE_CLASSNAME } from '@vfde-brix/ws10/accordion';
import { mountEditorialAccordion } from '../../../components/Accordion';
import { TABS_BASE_CLASSNAME } from '@vfde-brix/ws10/tabs';
import { mountTabs } from '../../../components/Tabs';

/**
 * Go through the dom and mount up editorial components
 */
export const mountEditorialContent = (): void => {
    mountComponents(ACCORDION_BASE_CLASSNAME, mountEditorialAccordion);
    mountComponents(TABS_BASE_CLASSNAME, mountTabs);
};

/**
 * General loop to mount up editorial components without additional special functionality
 */
export const mountComponents = (className: string, mountFunction: (parent: HTMLElement) => void) => {
    const containers = Array.from(document.getElementsByClassName(className));
    containers.forEach(element => {
        const parentElement = element.parentNode;

        if (parentElement instanceof HTMLElement) {
            mountFunction(parentElement);
        }
    });
};
