import {
    Selector,
    createSelector,
} from 'reselect';
import { NAMESPACE } from 'Constant';
import { initialState } from './reducer';
import { IFlyoutState } from 'Container/Flyout/interface';
import { RootState } from '@vfde-sails/core';

/**
 * Select state
 */
export const selectState = (state: RootState<IFlyoutState>): IFlyoutState => state[NAMESPACE] || initialState;
/**
 * Select injected state
 */
export const selectInjected = (): Selector<RootState<IFlyoutState>, IFlyoutState['isInjected']> =>
    createSelector(selectState, state => state.isInjected);
/**
 * Select tobi state
 */
export const selectIsTobiInitialized = (): Selector<RootState<IFlyoutState>, IFlyoutState['isTobiInitialized']> =>
    createSelector(selectState, state => state.isTobiInitialized);
/**
 * Select tobi state
 */
export const selectIsOpenedViaNavigation = (): Selector<RootState<IFlyoutState>, IFlyoutState['isOpenedViaNavigation']> =>
    createSelector(selectState, state => state.isOpenedViaNavigation);
/**
 * Select error
 */
export const selectError = (): Selector<RootState<IFlyoutState>, IFlyoutState['error']> =>
    createSelector(selectState, state => state.error);
