/* eslint-disable max-len */
import {
    FlyoutContent,
    IFlyoutGlobalPageOptions,
} from 'Container/Flyout/interface';

/**
 * Reads the default contant info from a variable that lives in
 * the window['VF'].SAILS.flyout namespace
 * This variable is set by simplicity (hotlines-templates-controller.js)
 */
export const getContactInfoFromWindow = (): FlyoutContent | undefined => window.VF?.SAILS?.flyout?.simplicityContactInfo;

/**
 * Reads the hidden notch value
 * window['globalPageOptions'].flyout.hiddenNotch
 * Indicating if the flyout should be visible or not
 */
export const getHiddenNotchFromWindow = (): IFlyoutGlobalPageOptions['hiddenNotch'] => window.globalPageOptions?.flyout?.hiddenNotch || false;
/**
 * Reads the url
 * window['globalPageOptions'].flyout.url
 * Location of the flyout content
 */
export const getContentUrlFromWindow = (): IFlyoutGlobalPageOptions['url'] | undefined => window.globalPageOptions?.flyout?.url;
/**
 * Reads the CALL_BUTTON_TEXT
 * window['globalPageOptions'].flyout.CALL_BUTTON_TEXT
 * Text for the hotline button
 */
export const getHotlineButtonTextFromWindow = (): IFlyoutGlobalPageOptions['CALL_BUTTON_TEXT'] | undefined => window.globalPageOptions?.flyout?.CALL_BUTTON_TEXT;
/**
 * Reads the CALL_HEADER_TEXT
 * window['globalPageOptions'].flyout.CALL_HEADER_TEXT
 * Text for the hotline header
 */
export const getHotlineHeaderFromWindow = (): IFlyoutGlobalPageOptions['CALL_HEADER_TEXT'] | undefined => window.globalPageOptions?.flyout?.CALL_HEADER_TEXT;
