import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/ws10/core';
import {
    createLinkTiles,
    ILinkTilesItemProperties,
    ILinkTilesProperties,
    LINK_TILES_LINK_CLASSNAME,
} from '@vfde-brix/ws10/link-tiles';
import {
    FLYOUT_VIEW_CLASSNAME,
    LINK_TILES_CONTAINER,
} from 'Container/Flyout/constant';
import { changeView } from 'Container/Flyout/helper/uiHelpers';

/**
 * mount LinkTile
 */
export const mountLinkTiles = (wrapperId?: string): void => {
    const wrapperElement = document.getElementById(wrapperId || LINK_TILES_CONTAINER);

    if (!wrapperElement) {
        return;
    }

    const views = document.getElementsByClassName(FLYOUT_VIEW_CLASSNAME);
    const linkTilesItems: ILinkTilesItemProperties[] = Array.from(views).map(container => {
        const stdLabel = container.getElementsByTagName('h1')[0]?.textContent || '';
        const imgMidRenderIconPath = `/simplicity/icons/${(container as HTMLElement).dataset.icon}.svg#icon`;

        return {
            imgMidRenderIconPath,
            stdLabel,
            linkHref: '#',
            business: NO_PATTERN_BUSINESS_LOGIC,
        };
    });

    const onClick = (event: MouseEvent) => {
        const target = event.target as Element;
        const clickedElementText = (target.closest(`.${LINK_TILES_LINK_CLASSNAME}`)?.textContent || '').trim();
        changeView(clickedElementText);
    };

    const props: ILinkTilesProperties = {
        linkTilesItems,
        business: { onClick },
    };

    createLinkTiles(wrapperElement, props);
};
