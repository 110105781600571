import produce from 'immer';
import {
    INJECT_CONTENT_SUCCESS,
    INITIALIZE_TOBI_SUCCESS,
    SAVE_ERROR,
} from './constant';
import { IFlyoutState } from 'Container/Flyout/interface';
import { FlyoutAction } from './actions';

/**
 * initialState
 */
export const initialState: IFlyoutState = {
    isInjected: false,
    isTobiInitialized: false,
    isOpenedViaNavigation: false,
    error: null,
};

/**
 * Reducer
 */
export default (state: IFlyoutState = initialState, action: FlyoutAction) =>
    produce(state, (draft: IFlyoutState) => {
        switch (action.type) {
            case INJECT_CONTENT_SUCCESS:
                draft.isInjected = true;
                draft.isOpenedViaNavigation = action.isOpenedViaNavigation;
                break;
            case INITIALIZE_TOBI_SUCCESS:
                draft.isTobiInitialized = true;
                break;
            case SAVE_ERROR:
                draft.error = action.errorMessage;
        }
    });
